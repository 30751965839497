@import '../../assets/css/colors.scss';

.wide-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark-blue;
  width: 322px;
  height: 52px;
  border-radius: 50px;
  &:hover {
    background-color: $color-blue-arsenic;
  }
  &.disabled {
    background-color: rgba(23, 23, 32, 0.15);
    cursor: not-allowed;
  }
  span {
    font-size: 16px;
    color: $color-white;
    font-weight: normal;
  }
}
