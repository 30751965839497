@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.login-page {
  @include flex-column-center;
  height: 100%;
  width: 100%;
  background-color: $color-dark-blue;
  overflow-y: auto;
  .content-wrapper {
    @include flex-column-center;
    .title {
      align-self: center;
      font-weight: 600;
      font-size: 36px;
      color: $color-dark-blue;
      margin-bottom: 19px;
      margin-top: 23px;
    }
    .form-container {
      @include flex-column-start-center;
      width: 100%;
    }
  }
}
