@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/variables.scss';
@import '../../../../assets/css/colors.scss';

.business-information-form {
  @include flex-row-start;
  justify-content: space-between;
  margin-top: 30px;

  .business-information-first-wrapper {
    display: flex;
    flex-direction: column;
    width: calc((100% - 64px) / 2);
  }

  .business-information-second-wrapper {
    display: flex;
    flex-direction: column;
    width: calc((100% - 64px) / 2);
  }
}

@media (max-width: $screen-tablet-width) {
  .business-information-form {
    flex-wrap: wrap;

    .business-information-first-wrapper {
      width: calc((100% - 10px) / 1);
      > .login-error-message {
        margin: 0;
      }
    }

    .business-information-second-wrapper {
      margin-top: 28px;
      width: calc((100% - 10px) / 1);
    }
  }
}
