@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/variables.scss';

.business-images-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .business-images-step-title {
    margin-bottom: 0;
  }

  .business-images-upload-button {
    .button-upload-image-button-wrapper {
      background: $color-blue-primary-buttons;
      border-radius: 50px;
      height: 42px;
      padding: 4px 8px;

      .button-upload-image-text {
        align-items: center;
        color: $color-white;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}
@media (max-width: $screen-tablet-width) {
  .business-images-wrapper {
    flex-wrap: wrap;
  }
}
