@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.verification-code {
  @include flex-column-center;
  height: 100%;
  width: 100%;
  background-color: $color-dark-blue;
  overflow-y: auto;
  .content-wrapper {
    @include flex-column-center;
    .title {
      align-self: center;
      font-weight: 600;
      font-size: 36px;
      color: $color-dark-blue;
      margin-bottom: 19px;
      margin-top: 23px;
    }
    .form-container {
      @include flex-column-start-center;
      width: 100%;
      margin: 40px;

      .code-input-error {
        padding: 1.5px;
        background: $color-pink-commerce;
        border-radius: 8px;
      }

      .character--inactive {
        background-color: $color-white;
      }

      .character--selected {
        color: $color-black;
        outline: 0px;
        position: relative;

        &:empty::after {
          animation: blink 1s infinite step-end;
          background-color: $color-blue-cursor-blink;
          content: '';
          display: block;
          height: 30px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 1px;
        }

        @keyframes blink {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
        }
      }

      .character-error {
        border: 1px solid $color-pink-commerce;
      }
    }
  }
}
.login-form {
  width: 100%;
  height: 95%;
  @include flex-column-center;
  .bottom-container {
    margin-bottom: 52px;
  }
}
