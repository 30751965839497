@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.register-page {
  background: $color-white;
  display: flex;
  min-height: 100vh;

  .image-section,
  .form-section {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }

  .image-section {
    background-color: $color-dark-blue;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    svg {
      align-self: flex-start;
      margin-left: 47px;
      margin-top: 49px;
    }
  }

  .form-section {
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh;
    overflow-y: auto;

    .form-content-fields {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      padding: 60px 80px 25px 80px;
      position: relative;
    }
  }
}

@media (max-width: $screen-desktop-width) {
  .register-page {
    .image-section {
      width: 40%;
    }

    .form-section {
      width: 60%;

      .form-content-fields {
        padding: 30px 20px 10px 20px;
      }
    }
  }
}

@media (max-width: $screen-tablet-width) {
  .register-page {
    flex-direction: column;

    .image-section {
      display: none;
    }

    .form-section {
      width: 100%;
    }
  }
}
