@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.preview-details {
  width: 88%;
  flex-direction: column;
  display: flex;
  justify-self: flex-end;
  margin-left: 20px;
  margin-right: 20px;
  border-top: 1px solid $color-gray-ghost;
  .preview-details-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding-top: 8px;
    padding-bottom: 8px;
    .preview-details-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $color-dark-blue;
      padding-left: 8px;
    }
    .preview-details-icon {
      justify-content: flex-end;
      right: 32.08px;
      position: absolute;
    }
  }
  .preview-details-items {
    @include flex-column-nowrap;
    padding-bottom: 24px;
    padding-left: 8px;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark-blue;
  }
}

@media (max-height: 719px) {
  .preview-details {
    .preview-details-title {
      padding-top: 6px;
      padding-bottom: 5px;
      .preview-details-text {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $color-dark-blue;
        padding-left: 8px;
      }
      .preview-details-icon {
        transform: rotate(270deg);
      }
    }
    .preview-details-items {
      @include flex-column-nowrap;
      padding-bottom: 24px;
      padding-left: 8px;
      font-size: 16px;
      line-height: 24px;
      color: $color-dark-blue;
    }
  }
}
