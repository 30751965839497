@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.body {
  .body-item {
    text-align: center;

    .main-location {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .selected-location-icon {
        margin-right: 5px;
      }
    }

    .location-actions-wrapper {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .trash-location-icon {
        cursor: pointer;
        margin-left: 27px;
        path {
          fill: $color-pink-commerce;
        }
      }
    }
  }
}
