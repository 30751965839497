@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.skeleton-tbody {
  height: initial !important;
  .body {
    .body-item {
      border-bottom: 1px solid $color-gray-ghost;
      font-size: 16px;
      font-weight: normal;
      height: 78px;
      line-height: 24px;
      text-align: center;
    }
    .title-item {
      padding-left: 13px;
      text-align: start;
      text-decoration-line: underline;
      max-width: 120px;
    }
    .status-item {
      text-transform: uppercase;
    }
  }
}
