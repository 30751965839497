@import '../../../../../assets/css/colors.scss';
@import '../../../../../assets/css//mixins.scss';

.login-error {
  @include flex-column-center;
  width: 100%;

  .error-content-wrapper {
    @include flex-column-center;
    margin-top: 15px;
    width: 335px;
    .text {
      color: $color-dark-blue;
      font-size: 16px;
      text-align: center;
      white-space: pre-line;

      &.title {
        font-weight: 600;
        font-size: 26px;
        margin: 0;
        line-height: 36px;
      }
    }

    .button-container {
      margin: 40px 0 40px 0;
    }
  }

  .close-button {
    margin: 20px 20px 0;
    align-self: flex-end;
    cursor: pointer;
    width: 11px;
    height: 11px;
    background: url('../../../../../assets/icons/close.svg') no-repeat center;
    &:hover {
      background: url('../../../../../assets/icons/close-hover.svg') no-repeat
        center;
    }
  }
}
