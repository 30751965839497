@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.create-deal-form {
  background-color: $color-white;
  margin-top: 10px;
  overflow-y: scroll;
  z-index: 1100;
  position: relative;
  .submit-button {
    @include flex-column-nowrap;
    margin-left: 33px;
    margin-right: 32px;
    margin-top: 18px;
    position: relative;
    padding-bottom: 165px;

    .button-wrapper {
      background-color: rgb(10, 114, 237);
      border: none;
      height: 48px;
      position: absolute;
      width: 100%;

      .button-text {
        color: $color-white;
      }
    }
  }
}
