@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.container {
  flex: 1;
  margin-left: 20px;
}
.container .title {
  color: $color-black;
  font-weight: 700;
  font-size: 36px;
  line-height: 125%;
}
.container .addNote {
  color: $color-blue-dark-profile-fields;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
}
.container .container-form {
  margin-top: 20px;
  width: 640px;
}
.container .button {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
