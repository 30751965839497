@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.preview-header {
  @include flex-row-screen-center;
  height: 72px;
  min-height: 45px;
  width: 100%;
  position: relative;
  .preview-header-icon {
    transform: rotate(90deg);
    left: 32.08px;
    position: absolute;
  }
  .preview-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: $color-dark-blue;
  }
}

@media (max-height: 719px) {
  .preview-header {
    height: 60px;
    .preview-header-icon {
    }
    .preview-title {
      font-size: 16px;
      line-height: 12px;
    }
  }
}
