@import '../../assets/css/colors.scss';

*:before,
*::after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

.loader-animate-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  transition: all 1.5s;
  width: 100%;

  .loader {
    animation-delay: -0.16s;
    animation: loader 1.5s infinite ease-out;
    border-radius: 50%;
    font-size: 13px;
    height: 14px;
    opacity: 0.67;
    position: relative;
    width: 14px;

    &.blue {
      color: $color-blue-primary-buttons;
    }
    &.white {
      color: $color-white;
    }

    &:before,
    &::after {
      animation: loader 1.5s infinite ease-out;
      border-radius: 50%;
      content: '';
      height: 14px;
      position: absolute;
      top: 0;
      width: 14px;
    }

    &::before {
      animation-delay: -0.32s;
      left: -15px;
      opacity: 1;
    }

    &::after {
      animation-delay: 1.75s;
      left: 15px;
      opacity: 0.54;
    }
  }
}

@keyframes loader {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
