@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.business-inputs-hours-container {
  @include flex-row-center;

  .business-inputs-separator {
    color: $color-gray-silver;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-inline: 8px;
  }

  .business-icon-remove-hour {
    cursor: pointer;
    margin-left: 24px;
  }

  .react-datepicker-wrapper {
    width: 101px;
  }

  .datepicker-hours {
    @include app-input;
    text-align: center;
    width: 101px;
  }

  @include datepicker-time-hours;
}
