@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.business-location-list-wrapper {
  @include flex-column-start-start;

  .btn-add-new-location-wrapper {
    margin-top: 37px;

    .button-wrapper {
      padding: 5px 15px;
      background: $color-blue-dodger;
      border-radius: 50px;

      .button-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $color-white;
      }
    }
  }
}
