@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.my-businesses-wrapper {
    @include flex-row-screen-start;
    margin-top: 53px;

    .card-business {
        @include flex-row-screen-start;
        background: $color-white-opacity-70;
        border-radius: 12px;
        border: 1px solid $color-gray-dark-opacity-70;
        box-shadow: 0px 6px 10px $color-black-opacity-80;
        height: 172px;
        width: 460px;

        img {
            border-bottom-left-radius: 12px;
            border-top-left-radius: 12px;
            height: 173px;
            width: 171px;
        }

        .content-business-card {
            display: flex;
            flex-direction: column;
            margin-left: 34px;
            padding: 5px;

            .business-name {
                color: $blue-cyan-dark-shade;
                font-size: 24px;
                font-weight: 600;
                line-height: 28px;
            }

            .view-detail {
                color: $color-blue-dodger;
                cursor: pointer;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                margin-top: 9px;
            }
        }
    }
}