@import '../../../../../../assets/css/colors.scss';
@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/variables.scss';
.profile-picture-wrapper {
  .business-images-files {
    @include flex-row-center;
    margin-bottom: 40px;

    .business-images-profile-picture {
      border-radius: 12px !important;
      border: 1px solid $color-gray-light;
      height: 160px;
      width: 162px;
    }

    .business-images-upload-button {
      .button-upload-image-button-wrapper {
        width: 181px;
      }
    }
  }

  @media (max-width: $screen-mobile-width) {
    .business-images-files {
      flex-direction: column;

      .business-images-profile-picture {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }
}
