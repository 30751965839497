@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.account-verification-step-form{

    margin-top: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #747479;

    .account-verfication-step-description{

        .account-verification-step-email{
            font-weight: bold;
        }
    }

    .account-verfication-step-business-days{
        display: flex;
        margin-top: 32px;
    }
}

