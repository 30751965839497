@import '../../assets/css/colors.scss';
.select-component {
  margin-bottom: 26px;
  .select-label {
    font-family: 'TitilliumWeb-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $color-blue-dark-profile-fields;
  }
  .option-item-container {
    width: 100%;
    cursor: pointer;

    &:hover {
      background: #ededed;
      color: #171720;
    }

    .option-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ededed;
      padding: 8px 0;
      margin-left: 8px;
      margin-right: 8px;
      font-size: 16px;
      height: 35px;
      color: #747479;
      display: flex;
      align-items: center;
      &.selected {
        color: #171720;
        transform: matrix(1, 0, 0, 1, 0, 0);
      }
    }
  }
}
