.duration-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .calendar-wrapper,
  .input-number-component {
    margin-top: 10px;
  }

  .input-number-component {
    .in-label {
      width: 67px;
    }
  }
}
