@import '../../../../assets/css/variables.scss';
@import '../../../../assets/css/colors.scss';

.business-physical-step-form{

    width: 100%;
    margin-top: 36px;

    .field-container{
        margin-top: 28px;
    }

    .field-container-state-zipcode{
        margin-top: 28px;
        display: flex;
    }
    
    .previous-next-buttons-container{
        margin-top: 64px;
        margin-bottom: 35px;
    }

    .field-label {
        font-family: $app-font-name;
        font-size: 16px;
        font-weight: 600;
        color: $color-blue-dark-profile-fields;
        margin-bottom: 1px;
        min-height: 25px;
        width: 40px;
      }
}