@import '../../assets/css/mixins.scss';
@import '../../assets/css/variables.scss';

.profile-edition-page {

  .create-deal-page-header-wrapper {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1px;
  }
  .profile-page-header-wrapper {
    box-shadow: none;
    margin-bottom: inherit;
  }

  .header-page span, .header-page svg {
    margin-bottom: 16px;
  }

  .profile-edition-page-content-wrapper {
    @include flex-row-center;
    align-items: initial;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: auto;

    .profile-edition-page-content {
      display: flex;
      flex-direction: column;
      margin-bottom: 120px;
      padding: 30px 60px 80px 60px;
    }

    @media (max-width: $screen-tablet-width) {
      .profile-edition-page-content {
        padding: 20px 30px 70px 30px;
      }
    }

    @media (max-width: $screen-mobile-width) {
      .profile-edition-page-content {
        padding: 10px 15px 60px 15px;
      }
    }
  }
}