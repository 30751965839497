@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.css';

.login-error-message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin: 0 0;
  .error-text {
    font-size: 14px;
    color: $color-red-caramine;
  }
  .icon {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
}
