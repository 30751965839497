@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.button-upload-image-wrapper {
  @include flex-row-start;
  align-items: center;
  flex-wrap: wrap;
  .button-upload-image-name {
    color: $color-gray-silver;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-right: 16px;
    margin-bottom: 2px;
  }
  .button-upload-image-text {
    text-align: center;
    color: $color-blue-register-steps;
    font-family: 'TitilliumWeb-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 1.2px;
  }
}

.button-upload-image-button-wrapper {
  align-items: center;
  background-color: $color-white;
  border-radius: 50px;
  border: 1px solid $color-blue-register-steps;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 134px;
}
