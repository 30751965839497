@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.table-fixed-head {
  border-collapse: collapse;
  margin-left: 2.3%;
  table-layout: fixed;
  width: 95%;
}
.table-fixed-head tbody {
  display: block;
  height: calc(100vh - 220px);
  overflow: auto;
  width: 100%;
}
.table-fixed-head thead tr {
  display: block;
}
.table-fixed-head th,
.table-fixed-head td {
  width: 300px;
}
