@import '../../../../assets/css/mixins.scss';

.create-account-step-form{
    width: 100%;
    margin-top: 36px;

    .field-container{
        margin-top: 28px
    }

    .phone-number-wrapper {
        @include flex-row-center;
        align-items: flex-start;
    }
    
    .bottom-container {
        margin-top: 64px;
    }

    .privacy-policy-eula-text{
        
        margin-top: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        flex-flow: wrap;
        justify-content: center;

        .underline-text{
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}