@import '../../../assets/css/colors.scss';
@import '../../../assets/css/mixins.scss';

.content-validation {
  font-size: 14px;
  color: $color-dark-blue;
  img {
    margin-right: 8px;
  }
  .result-wrapper {
    @include flex-row-center;
    width: calc(100% - 20px);
    .lng-safe,
    .lng-inapropiate {
      margin-left: 8px;
    }
    .lng-safe {
      color: $color-green-success;
    }
    .lng-inapropiate {
      color: $color-red-caramine;
    }
  }
}
