@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.main-users-container {
  .btn-add-new-user-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    padding-top: 10px;

    .title-text {
      align-items: center;
      display: flex;
      color: $blue-cyan-dark-shade;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .users-btn-add {
      background-color: $color-blue-dodger;
      span {
        color: $color-white;
      }
    }
  }

  .users-table {
    margin-left: inherit;
    width: 100%;
    table tbody {
      height: initial;
    }
  }
}
