@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.register-page {
    background: $color-white;
    display: flex;
    min-height: 100vh;
  
    .business-section,
    .form-section {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      width: 50%;
    };

		.business-section {
			background-color: $color-blue-link;
			justify-content: center;
			flex-direction: column;
			
			.logo {
				position: absolute;
				top: 49px;
				left: 47px;
			}
			
			.invited-to-text {
				font-weight: 600;
				font-size: 24px;
				line-height: 150%;
				display: flex;
				align-items: center;
				color: #E1E3E5;
				margin-bottom: 20px;
			}
			
			.business-name {
				font-weight: 600;
				font-size: 20px;
				line-height: 150%;
				color: #F4F5F6;	
				margin-top: 12px;
			}
			
			.image {
				border-radius: 52px;
			}
		};

		.form-section {
			flex-direction: column;
			flex-wrap: nowrap;
			height: 100vh;
			overflow-y: auto;

			.form-content-fields {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
				padding: 60px 80px 25px 80px;
				position: relative;
			}
		}

}

@media (max-width: $screen-desktop-width) {
  .register-page {
    .image-section {
      width: 40%;
    }

    .form-section {
      width: 60%;

      .form-content-fields {
        padding: 30px 20px 10px 20px;
      }
    }
  }
}

@media (max-width: $screen-tablet-width) {
  .register-page {
    flex-direction: column;

    .image-section {
      display: none;
    }

    .form-section {
      width: 100%;
    }
  }
}
