@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.modal-confirmation-wrapper {
  @include flex-column-center;
  border-radius: 16px;
  position: 'absolute';
  white-space: pre-line;
  min-width: 250px;
  min-height: 250px;
  .modal-confirmation-buttons {
    @include flex-row-center;
  }

  .modal-confirmation-close-icon {
    cursor: pointer;
    align-self: flex-end;
  }

  .modal-confirmation-icon {
    width: 10;
    height: 10;
  }
  .modal-confirmation-buttons {
    margin-bottom: 32px;
  }
  .modal-confirmation-button-cancel {
    margin-right: 8px;
  }
  .modal-confirmation-button-confirm {
    margin-left: 8px;
  }
}
