@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.no-results {
  @include flex-column-center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  color: $color-gray;
  .no-results-icon-position {
    margin-bottom: 13.74px;
  }
  .label-1 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .label-2 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }
  .label-3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
}
