@import '../../../assets/css/mixins.scss';
@import '../../../assets/css/variables.scss';
@import '../../../assets/css/colors.scss';

.input-text-wrapper {
  margin-bottom: 4px;
  .field-input {
    @include app-input;
    width: 100%;
  }

  .field-input-font {
    font-family: $app-font-name;
  }
  .field-label {
    color: $color-blue-dark-profile-fields;
    font-family: $app-font-name;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1px;
    min-height: 25px;
    width: 40px;

    .is-optional {
      color: $blue-cyan-dark-shade;
      font-size: 11px;
      font-style: italic;
      font-weight: 600;
      line-height: 19px;
      margin-left: 3px;
      opacity: 0.5;
    }
  }
}
