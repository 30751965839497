@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.loading {
  @include flex-column-center;
  background-color: $color-black;
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5000;
}

.location-search-input {
  background-color: $color-white;
  border: 1px solid $color-gray-silver;
  box-sizing: border-box;
  color: $color-dark-blue;
  font-size: 16px;
  height: 40px;
  outline: none;
  padding: 8px;
  width: 100%;
}
.autocomplete-dropdown-container {
  @include flex-column-start-center;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  margin-top: 8px;
  max-height: 172px;
  overflow-x: hidden;
  overflow-y: auto;

  .autocomplete-dropdown-item {
    border-bottom: 1px solid $color-gray-light;
    color: $color-gray-silver;
    font-family: $app-font-name;
    font-size: 16px;
    line-height: 24px;
    margin-inline: 11.4px;
    padding-bottom: 9px;
    padding-top: 9px;
    width: 90%;
    z-index: 5000;
  }
}

.autocomplete-field-input {
  @include app-input;
  width: 100%;
}
