@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.head-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $color-dark-blue;
  text-align: start;
  padding-left: 16px;
  padding-top: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-normal {
  cursor: auto;
}
.head-item-center {
  text-align: center;
}
.rounded-left {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.rounded-right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.flex-row-center {
  @include flex-row-screen-center;
}
.flex-row-start {
  @include flex-row-center;
}

.flex-col {
  @include flex-column-start-center;
  margin-left: 12px;
  .rotate {
    transform: rotate(180deg);
    margin-bottom: 4px;
  }
}
