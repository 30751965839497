@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/variables.scss';

.business-header-profile {
  @include flex-row-center-space-between;
  flex-wrap: wrap;

  .info-business-wrapper {
    align-items: center;
    display: flex;

    .business-header-img {
      border-radius: 50%;
      display: flex;
      height: 90px;
      position: relative;
      width: 90px;

      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }

      .icon-camera {
        align-items: center;
        background-color: $color-blue-primary-buttons;
        border-radius: 50%;
        border: 4px solid $color-white;
        bottom: 0;
        display: flex;
        height: 39px;
        justify-content: center;
        position: absolute;
        right: -5px;
        width: 39px;

        label {
          display: contents;

          svg {
            height: 17px;
            width: 20px;
          }
        }
      }
    }

    .business-header-info {
      display: flex;
      flex-direction: column;
      margin-left: 17px;

      .business-name {
        color: $blue-cyan-dark-shade;
        display: flex;
        font-size: 36px;
        font-weight: 600;
        line-height: 52px;
      }

      .business-email {
        color: $color-gray-silver;
        display: flex;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
      }

      @media (max-width: $screen-tablet-width) {
        .business-name {
          font-size: 25px;
          line-height: 40px;
        }
        .business-email {
          font-size: 18px;
          line-height: 28px;
        }
      }

      @media (max-width: $screen-mobile-width) {
        .business-name {
          font-size: 17px;
          line-height: 35px;
        }
        .business-email {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
  }

  .badge-message-wrapper {
    display: flex;
    margin: 18px 15px;
  }
}
