@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.body {
  .body-item {
    border-bottom: 1px solid $color-gray-ghost;
    color: $color-dark-blue;
    font-size: 16px;
    font-weight: normal;
    height: 78px;
    line-height: 24px;
    padding-left: 16px;
    text-align: justify !important;
  }
  .title-item {
    padding-left: 13px;
    text-align: start;
    text-decoration-line: underline;
  }
  .pointer-item {
    cursor: pointer;
  }
  .pointer-item-disabled {
    cursor: not-allowed;
  }
  .status-item {
    text-transform: uppercase;
  }
  .exclamation-circle-end-date-wrapper {
    @include flex-row-screen-center;
    justify-content: flex-start;
    .exclamation-circle-end-date-icon {
      margin-top: 5px;
      margin-left: 4px;
    }
  }
  .exclamation-circle-end-date-tooltip {
    background-color: $color-dark-blue;
    color: $color-white;
    border-radius: 4px;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
  }
  .color-green {
    color: $color-green-status;
    font-weight: 600 !important;
  }
  .color-blue {
    color: $color-blue-status;
    font-weight: 600 !important;
  }
  .color-red {
    color: $color-red-status;
    font-weight: 600 !important;
  }
  .color-yellow {
    color: $color-yellow-status;
    font-weight: 600 !important;
  }
  .color-gray-dark {
    color: $color-gray-dark-status;
    font-weight: 600 !important;
  }
  .color-gray {
    color: $color-gray-status;
    font-weight: 600 !important;
  }
  .color-orange {
    color: $color-orange-status;
    font-weight: 600 !important;
  }
}

.div-drop-down-action-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ul-drop-down-action-list {
  align-items: flex-end;
  background: white;
  border-radius: 8px;
  display: flex;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
  flex-direction: column;
  height: 96px;
  padding-left: 10px;
  position: absolute;
  right: 0px;
  top: 15px;
  width: 108px;
  z-index: 1;
}
.li-drop-down-action-list {
  color: black;
  font-weight: normal;
  margin: 0;
  padding: 14px 5px 10px;
  width: 100%;
  z-index: 7;

  &:hover {
    background: $color-gray-light;
  }
}
.ellipsis-icon-action-list {
  border-radius: 2px;
  cursor: pointer;
  padding: 4px 10px;

  &:hover {
    background: $color-gray-light;
  }

  &:active {
    background: $color-gray-light;
  }
}
.ellipsis-icon-action-list-disabled {
  border-radius: 2px;
  cursor: not-allowed;
  padding: 4px 10px;

  path {
    fill: $color-grayish-blue;
  }
}
.list-edited-text {
  color: $color-gray-silver;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: 12px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  .tooltip {
    background-color: $color-blue-primary-buttons;
  }
}
