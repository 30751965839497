@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.confirmation-popup {
  background-color: $blue-cyan-dark-shade;
  border-radius: 8px;
  bottom: 25px;
  cursor: pointer;
  height: 54px;
  opacity: 1;
  padding: 5px 10px;
  position: absolute;
  right: calc(50% - (428px / 2));
  transition: opacity 0.2s;
  width: 428px;
  z-index: 110;

  &.error {
    background-color: $color-red-caramine;

    svg path {
      fill: $color-white;
    }
  }

  &:hover {
    opacity: 0.9;
  }
  .confirmation-popup-content {
    @include flex-row-screen-center;
    width: 100%;
    height: 100%;
    .confirmation-popup-text {
      margin-left: 12px;
      color: $color-white;
      font-weight: 600;
    }
  }
}
