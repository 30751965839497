@import '../../assets/css/mixins.scss';
.input-number-component {
  max-height: 33px;
  .input-wrapper {
    position: relative;
    .input-number {
      @include app-input;
      width: 121px;
      max-height: 33px;
      padding-left: 33.6%;
      font-size: 16px;
      font-weight: normal;
      color: #747479;
      &.disabled {
        background: #ededed;
        border: 1px solid #747479;
        box-sizing: border-box;
      }
    }
    .add-button {
      cursor: pointer;
      position: absolute;
      right: 7%;
      top: 34%;
    }
    .substract-button {
      cursor: pointer;
      position: absolute;
      right: 26%;
      top: 34%;
    }
  }
  .in-label {
    margin: 8px;
    margin-left: 0;
    &.disabled {
      opacity: 0.25;
    }
  }
}
