@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.offer-tag {
  position: absolute;
  width: 66px;
  height: 32px;
  left: 8px;
  top: 8px;
  background-color: $color-red-caramine;
  font-size: 42px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: $color-white;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  .offer-tag-text {
    position: relative;
    top: 3px;
  }
}
