@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.create-ad-page {
  .create-ad-page-header-wrapper {
    padding-bottom: 16px;
    background-color: $color-white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    z-index: 2000;
  }
  .create-ad-page-content-wrapper {
    display: flex;
  }
}
