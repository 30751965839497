@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.color-text-white{
    color: $color-white;
}

.color-text-dark-blue{
    color: $color-dark-blue;
}

.create-account-link-button{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    text-decoration-line: underline;
    justify-content: center;
    cursor: pointer;
}