@import './../../assets/css/colors.scss';
@import './../../assets/css/mixins.scss';

.calendar-wrapper {
  @include flex-row-start;
  flex-wrap: wrap;
  .react-datepicker {
    border: none !important;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.15));
    font-family: 'TitilliumWeb-Regular' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 24px !important;
    width: 260px !important;
    .react-datepicker__header {
      background-color: $color-white;
      width: 260px !important;
      .react-datepicker__current-month {
        padding-top: 7px;
        padding-bottom: 5px;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
        }
      }
    }
    .react-datepicker__month {
      .react-datepicker__month-container {
        .react-datepicker__week {
          .react-datepicker__day {
          }
        }
      }
    }
  }
  .react-datepicker__navigation {
    top: 10px !important;
  }
  .react-datepicker__triangle {
    visibility: hidden;
  }

  .disabled-date {
    pointer-events: none;
    color: $color-gray !important;
  }
  .react-datepicker__day--selected {
    background-color: $color-dark-blue !important;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: $color-dark-blue !important;
  }
  .react-datepicker__day--today {
  }
  .react-datepicker__input-container input {
    width: 103px;
    max-height: 16px;
    color: $color-gray-silver;
    outline: none;
    padding: 8px;
    border: 1px solid $color-gray-silver !important;
  }
  .react-datepicker-ignore-onclickoutside {
    width: 103px;
    max-height: 16px;
    color: $color-gray-silver;
    outline: none;
    padding: 8px;
    background-color: $color-white-cultured;
    border: 1px solid $color-gray-silver !important;
    &::after {
      background-image: url('../../assets/icons/calendar.svg');
      background-size: 13px 13px;
    }
  }

  .calendar-text {
    font-size: 16px;
    line-height: 24px;
    color: $color-dark-blue;
    margin-right: 8px;
    margin-top: 4px;
  }
  .calendar-from {
    @include flex-row-start;
    flex-wrap: nowrap;
    margin-right: 18px;
    .react-datepicker-wrapper {
      width: 121px;
    }
  }
  .calendar-to {
    @include flex-row-start;
  }
}
