$color-black-opacity-2: rgba(0, 0, 0, 0.2);
$color-black-opacity-80: rgba(0, 0, 0, 0.08);
$color-black: #000000;

$color-white-cultured: #f6f6f6;
$color-white-opacity-40: rgba(255, 255, 255, 0.4);
$color-white-opacity-70: rgba(255, 255, 255, 0.7);
$color-white-smoke: #f8f8f8;
$color-white: #ffffff;

$blue-cyan-dark-shade: #394954;
$color-blue-arsenic: #3b3b4a;
$color-blue-badge-background: #e5f1ff;
$color-blue-cian-light-shadow: #a5c9e2;
$color-blue-cornflower-rgba: rgba(93, 159, 234, 0.15);
$color-blue-cornflower: #5d9fea;
$color-blue-cursor-blink: #1a3866;
$color-blue-dark-profile-fields: #0c4d9d;
$color-blue-dark-profile: #052143;
$color-blue-dark-tabs: #0f63c9;
$color-blue-dodger: #1b7aee;
$color-blue-filter: #0078ff;
$color-blue-hawkes: #dcebfc;
$color-blue-link: #0a72ed;
$color-blue-primary-buttons: #0a72ed;
$color-blue-register-steps: #0078ff;
$color-blue-status: #26b2f7;
$color-dark-blue-rgba: rgba(23, 23, 32, 0.5);
$color-dark-blue: #171720;

$color-gray-badge-text: #56566e;
$color-gray-dark-opacity-70: rgba(57, 73, 84, 0.7);
$color-gray-dark-status: #4d4d4d;
$color-gray-divider: #cecece;
$color-gray-ghost: #d1d1d2;
$color-gray-light-opacity-70: rgba(237, 237, 237, 0.7);
$color-gray-light-opacity: rgba(237, 237, 237, 0.4);
$color-gray-light-shade: #e7e7e7;
$color-gray-light: #ededed;
$color-gray-silver: #747479;
$color-gray-status: #7d7d7d;
$color-gray: #9c9c9c;
$color-grayish-blue: #dcdcde;

$color-red-caramine: #de4842;
$color-red-status: #ed5858;

$color-green-cyan-light: #e1f4ec;
$color-green-mint-rgba: rgba(69, 185, 129, 0.15);
$color-green-mint: #45b981;
$color-green-status: #00ba34;
$color-green-success: #36b37e;

$color-orange-marigold-rgba: rgba(243, 166, 35, 0.1);
$color-orange-marigold: #f3a623;
$color-orange-status: #ff9720;

$color-yellow-selective: #f6b603;
$color-yellow-status: #ffbe00;

$color-pink-commerce: #ff0050;
$color-pink-rgba: rgba(233, 101, 162, 0.15);
$color-pink: #e965a2;

$color-violet-lavender-rgba: rgba(162, 126, 225, 0.15);
$color-violet-lavender: #a27ee1;
