@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.row-tab-list {
  @include flex-row-start;
  border-bottom: 0.7px solid #b3bbc3;
}
.tab-selected {
  color: $color-dark-blue;
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
  border-bottom: 2px solid $color-dark-blue;
}
.create-button-position {
  position: absolute;
  right: 32px;
}

ul.inline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

ul.inline,
li {
  display: inline-block;
  margin-left: -14px;
  padding-block: 16px;
  margin-right: 40px;
  color: $color-gray-silver;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
}
.text {
  color: $color-gray-silver;
}
.textTab {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.textQuantity {
  vertical-align: baseline;
  color: $color-gray-silver;
  font-size: 16px;
  line-height: 24px;
}
.pointSeparator {
  color: $color-dark-blue;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-inline: 4px;
}

.list-ads-preview-modal-content {
  margin: 0 133px;
}
.list-ads-preview-modal-title {
  @include flex-column-center;
  color: $color-dark-blue;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 28px;
  margin-top: 8px;
  font-weight: bold;
}

.deal-preview-container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 100px;
}

#main {
  flex-grow: 1;
}
