@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.modal-confirmation-wrapper {
  @include flex-column-center;
  border-radius: 16px;
  position: 'absolute';
  white-space: pre-line;
  min-width: 250px;
  min-height: 250px;

  .modal-confirmation-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: $color-dark-blue;
    margin-top: 11px;
    margin-bottom: 8px;
  }

  .modal-confirmation-message {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark-blue;
    margin-bottom: 8px;
  }

  .soft-desc-text {
    font-weight: 400;
    color: $color-gray-dark-status;
  }

  .modal-dont-show-again {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .text {
      color: $color-gray-silver;
      font-family: $app-font-name;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .modal-confirmation-close-icon {
    cursor: pointer;
    align-self: flex-end;
  }

  .modal-confirmation-icon {
    width: 10;
    height: 10;
  }

  .modal-confirmation-buttons {
    margin-bottom: 32px;
  }

  .modal-confirmation-button-cancel {
    margin-right: 8px;
  }

  .modal-confirmation-button-confirm {
    margin-left: 8px;
  }
  .modal-confirmation-button-confirm-no-margin {
    margin-left: 0px ;
    display: flex;
  }
}
