@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/colors.scss';

.user-info {
  @include flex-row-center;
  border-top: 1px solid $color-gray-light-opacity;
  color: $color-white;
  margin-inline: 28px;
  padding-top: 41px;
  .user-info-image {
    border: 2px solid transparent;
    border-radius: 23px;
    &:hover {
      border: 2px solid $color-blue-dodger;
      cursor: pointer;
    }
  }
  .user-info-text {
    @include flex-column-nowrap;
    margin-left: 12px;
    .user-info-name {
      font-size: 13px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: -4px;
    }
    .user-info-contact {
      font-size: 10px;
      line-height: 24px;
      text-decoration: underline;
    }
  }
}