@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.buttons-actions-wrapper {
  @include flex-row-end-center;
  margin: 30px 0px;

  > :first-child {
    border: 1px solid $blue-cyan-dark-shade;
    margin-right: 16px;

    .button-text {
      color: $blue-cyan-dark-shade;
    }
  }

  > :nth-child(2) {
    background-color: $color-blue-dodger;

    span {
      color: $color-white;
    }
  }
}
