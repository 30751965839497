@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.create-deal-preview {
  @include flex-column-center;
  background-color: $color-gray-light;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  .create-deal-preview-row {
    @include flex-row-screen-center;
    
  }
  .create-deal-preview-message {
    color: $color-gray-silver;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: normal;
    position: fixed;
    bottom: 10px;
  }
}
