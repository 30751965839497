@import '../../../../../../assets/css/colors.scss';

.change-email-form {
  margin-top: 49px;

  form {
    > .text-field {
      margin-bottom: 29px;
    }

    .login-error-message {
      margin: 0;
    }

  }

  .change-email-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 98px;

    > :first-child {
      border: 1px solid $color-gray-status;
      margin-right: 15px;
      width: 80px;

      .button-text {
        color: $color-gray-status;
      }
    }

    :nth-child(2) {
      background-color: $color-blue-dodger;
      width: 158px;

      .button-text {
        color: $color-white;
      }
    }
  }
}
