@import '../../assets/css/colors.scss';

.main-location-container {
  width: 100%;
  .title-text {
    color: $blue-cyan-dark-shade;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 20px;
  }

  .table-fixed-head {
    margin-left: 0;
    margin-top: 18px;
    width: 100%;

    & .table {
      width: 100%;
    }

    & .table .head {
      display: flex;
      justify-content: space-between;

      & .head-item {
        padding-top: initial;
      }
    }

    & th,
    & td {
      display: flex;
      align-items: center;
      width: 100%;
    }

    table tbody {
      height: inherit;
      & tr.body {
        display: flex;
        justify-content: space-between;

        & .body-item:nth-child(2) {
          padding-right: 10px;
        }
      }
    }
  }

  .selected-address {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .raio-option-content {
      display: flex;
      flex-direction: column;

      .address {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      .geographic-data {
        color: $color-gray-silver;
      }
    }

    .remove-address-icon {
      cursor: pointer;

      path {
        fill: $color-gray-silver;
      }
    }
  }
}
