@import '../../assets/css/mixins.scss';
@import '../../assets/css/variables.css';
@import '../../assets/css/colors.scss';

.password-security-container {
  display: flex;
  flex-direction: row;

  %base-styles {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    height: 26px;
    justify-content: center;
    line-height: 18px;
    margin-right: 4px;
    padding: 4px;
  }

  %base-invalid-styles {
    @extend %base-styles;
    background: $color-gray-light;
    color: $color-gray-silver;
  }

  %base-valid-styles {
    @extend %base-styles;
    background: $color-green-cyan-light;
    color: $color-green-success;
    flex-basis: content;

    .check-validation {
      margin-left: 7.32px;
      margin-right: 7.31px;
    }
  }

  .invalid-characters {
    @extend %base-invalid-styles;
    width: 76px;
  }

  .invalid-upparcase {
    @extend %base-invalid-styles;
    width: 60px;
  }

  .invalid-number {
    @extend %base-invalid-styles;
    width: 49px;
  }

  .invalid-special-character {
    @extend %base-invalid-styles;
    width: 87px;
  }

  .valid-characters {
    @extend %base-valid-styles;
    width: 96px;
  }

  .valid-upparcase {
    @extend %base-valid-styles;
    width: 80px;
  }

  .valid-number {
    @extend %base-valid-styles;
    width: 69px;
  }

  .valid-special-character {
    @extend %base-valid-styles;
    width: 107px;
  }
}
