@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.character-counter-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  .character-counter-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $color-gray-silver;
  }
}
