@import '../../../../assets/css/variables.scss';
@import '../../../../assets/css/colors.scss';

.business-hour-form {
  .delete-all-hours-action {
    color: $color-pink-commerce;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 30px;
    text-decoration-line: underline;
  }
  form {
    .container-day {
      .business-inputs-hours-container {
        svg {
          margin-left: 5px;
        }
      }
    }
  }
}
