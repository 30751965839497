@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.radio-button-component {
  @include flex-row-center;
  cursor: pointer;
  margin-top: 12px;
  .radio-button {
    width: 12px;
    height: 12px;
    border: 1px solid $color-blue-dodger;
    border-radius: 50%;
    padding: 1px;
    margin-right: 16px;
    .selected {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $color-blue-dodger;
    }
  }
  .radio-button-label {
    line-height: 24px;
    font-size: 16px;
    color: $color-dark-blue;
  }
}
