@import './colors.scss';

@mixin flex-column-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

@mixin flex-row-end-center {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

@mixin flex-column-start-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

@mixin flex-column-start-start {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

@mixin flex-column-space-between {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@mixin flex-column-nowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@mixin flex-row-center {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
@mixin flex-row-center-wrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@mixin flex-row-start-wrap {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@mixin flex-row-start {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
}
@mixin flex-row-screen-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
@mixin flex-row-end {
  align-items: flex-end;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}
@mixin flex-row-screen-start {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

@mixin flex-row-center-space-between {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@mixin flex-row-start-space-between {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@mixin flex-column-start-space-between {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@mixin input-autofull {
  -webkit-box-shadow: 0 0 0px 1000px $color-gray-light inset;
  border: 1px solid $color-dark-blue !important;
  box-shadow: 0 0 0px 1000px $color-gray-light inset;
}

@mixin app-input {
  background-color: $color-white;
  border: 1px solid $color-gray-silver;
  box-sizing: border-box;
  color: $color-dark-blue;
  font-family: 'TitilliumWeb-Regular';
  font-size: 16px;
  height: 40px;
  outline: none;
  padding: 8px;
  &:-webkit-autofill {
    @include input-autofull;
  }
  &:-webkit-autofill:hover {
    @include input-autofull;
  }
  &:-webkit-autofill:focus {
    @include input-autofull;
  }
  &::placeholder {
    color: $color-gray-silver;
    font-family: 'TitilliumWeb-Regular';
    font-size: 15px;
  }
  &:focus {
    background-color: $color-white-cultured;
    border: 1px solid $color-dark-blue;
  }
  &.error {
    border: 1px solid $color-red-caramine !important;
  }
  &.disabled {
    border: 1px solid $color-gray-status;
    box-sizing: border-box;
    color: $blue-cyan-dark-shade;
    cursor: not-allowed;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    opacity: 0.5;
  }
}

@mixin datepicker-time-hours {
  .react-datepicker {
    display: flex;
    align-items: center;

    .react-datepicker__input-time-container {
      display: none;
    }

    .react-datepicker__time-container {
      width: 90px;

      .react-datepicker__header {
        display: none;
      }

      .react-datepicker__time {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        display: flex;
        width: 100%;

        .react-datepicker__time-box {
          display: flex;
          flex-direction: row;
          flex: auto;
          margin-left: 5px;

          ul {
            overflow-x: hidden;

            li {
              color: $color-dark-blue;
              font-family: $app-font-name;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              padding: 2px 15px;
            }
            .react-datepicker__time-list-item {
              padding: 4px 15px !important;
            }
            .react-datepicker__time-list-item--selected {
              background-color: $color-white !important;
              color: initial !important;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
