@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.header-page {
  @include flex-row-center;
  margin-top: 24px;
  margin-left: 2.3%;
  background-color: $color-white;
  .header-page-text {
    color: $color-dark-blue;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    margin-left: 20px;
  }
  .header-page-icon-chevron {
    transform: rotate(90deg);
    cursor: pointer;
  }
  .header-page-icon-question {
    margin-left: 9.5px;
    cursor: pointer;
    outline: none;
  }
  .header-page-tooltip {
    background-color: $color-dark-blue;
    color: $color-white;
    border-radius: 4px;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    font-family: 'TitilliumWeb-Regular';
    font-style: normal;
    width: 313px;
    z-index: 3000;
  }
}

@media (max-height: $screen-tablet-height) {
  .header-page {
    margin-top: 11px;
    .header-page-text {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
