@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.business-day-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;

  .business-day-container {
    align-items: center;
    display: flex;
    margin-right: 43px;

    .business-day-label {
      color: $color-blue-dark-profile-fields;
      flex: 1;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-right: 26.19px;
      width: 80px;
    }

    .business-day-switch {
      display: flex;
      margin-right: 18px;
    }

    .business-day-opening {
      color: $blue-cyan-dark-shade;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      opacity: 0.5;

      &.open {
        opacity: 1;
      }
    }
  }

  .business-hours-container {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
  }

  .business-actions-container {
    display: flex;
    flex-wrap: wrap;

    > :first-child {
      margin-right: 20px;
    }
  }
}

@media (max-width: $screen-tablet-width) {
  .business-day-wrapper {
    .business-actions-container {
      flex-wrap: nowrap;
    }
  }
}

@media (max-width: $screen-midle-tablet-width) {
  .business-day-wrapper {
    flex-wrap: wrap;
  }
}
