@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.form-field {
  @include flex-column-nowrap;
  margin-left: 33px;
  margin-right: 32px;
  margin-top: 24px;
  padding-bottom: 22px;
  position: relative;

  &.with-border {
    border-bottom: 1px solid $color-gray-ghost;
  }

  .form-title {
    color: $color-dark-blue;
    display: flex;
    flex: 1;
    flex-direction: row;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    justify-content: space-between;
    line-height: 24px;
  }
  .form-description {
    color: $color-gray-silver;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
  }
  .form-underlined-link {
    color: $color-dark-blue;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
  }
  .form-children {
    margin-top: 12px;
  }
}
