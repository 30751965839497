@import '../../../../assets/css/variables.scss';

.tag {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

    img {
      margin-right: 8px;
    }
}

@media (max-width: $screen-mobile-width) {
      
    .tag {
        margin-right: 14px;
    
        img {
            margin-right: 4px;
        }
    }
}