@import '../../../../../../assets/css/colors.scss';
@import '../../../../../../assets/css/mixins.scss';

.business-images-files {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  position: relative;
  .business-image {
    margin-right: 24px;
    margin-bottom: 24px;
  }
  .business-images-profile-picture {
    border-radius: 116px;
    border: 1px solid $color-gray-light;
  }
}

.business-image-wrapper {
  position: relative;

  .business-images-close {
    left: initial !important;
    right: 0 !important;
    top: 0 !important;
    margin-right: 14px !important;
  }
}
