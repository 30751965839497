@import '../../../../assets/css/colors.scss';

.business-hour-step-form {
  width: 100%;
  margin-top: 32px;

  .delete-all-hours-action {
    color: $color-pink-commerce;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 30px;
    text-decoration-line: underline;
  }

  .business-description-bottom-buttons {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }
}
