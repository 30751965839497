@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/colors.scss';

.condition-list-wrapper {
  @include flex-column-start-center;
  .condition-list-item-wrapper {
    @include flex-row-start;
    width: 100%;
    height: 48px;
    align-items: center;
    .condition-list-item-icon {
      margin-top: 6px;
      padding-left: 22px;
      padding-right: 18px;
      cursor: pointer;
    }
    .condition-list-item-text {
      color: $color-dark-blue;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .condition-list-input {
    color: $color-gray-silver;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    border: 0ch;
    font-family: 'TitilliumWeb-Regular';
  }
}
