@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.msg-error {
  @include flex-row-start;
  align-items: center;
  padding-right: 16px;
  .msg-error-text {
    color: $color-red-caramine;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }
  .msg-error-icon {
    padding-right: 4px;
  }
}
