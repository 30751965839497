@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.form-number-phone-field {
  @include flex-column-nowrap;
  margin-top: 28px;
  position: relative;

  .form-number-phone-title{
    color: $color-blue-dark-profile-fields;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-style: normal;

    .is-optional {
      color: $blue-cyan-dark-shade;
      font-size: 11px;
      font-style: italic;
      font-weight: 600;
      line-height: 19px;
      margin-left: 3px;
      opacity: 0.5;
    }
  }
}