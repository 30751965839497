@import '../../assets/css/colors.scss';

.tap-panel-wrapper {
  margin-top: 43px;

  .tab-component {
    display: inline-block;
    position: relative;
    list-style: none;
    cursor: pointer;
    margin-right: 58px;
  }
  
  .tab-list {
    border-bottom: 1px solid $color-gray-divider;
    padding-inline-start: 14px;

    li {
      padding-block: 0;
    }
  }
}