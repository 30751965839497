@import '../../assets/css/colors.scss';

.button-wrapper {
  align-items: center;
  border-radius: 50px;
  border: 1px solid $color-blue-register-steps;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  opacity: 1;
  width: 134px;

  .button-icon {
    margin-right: 8.74px;
  }

  .button-text {
    color: $color-blue-register-steps;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
  }
}

.disable {
  border: 1px solid $color-gray-divider;
  cursor: not-allowed;
  opacity: 0.5;

  .button-text {
    color: $color-gray-divider;
  }
}
