@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.create-account-progress-bar-parent{

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .create-account-progress-bar-title{
        color: $color-dark-blue;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        font-style: normal;
    }

    .parent-bar {
        background: $color-gray-light-shade;
        border-radius: 50px 0 0 50px;
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        width: 100%;
    
        .progress {
            height: 100%;
            border-radius: 50px 0 0 50px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
        }
    }
}