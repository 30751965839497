@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/colors.scss';

.left-menu-toggle {
  background-color: $color-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 23px;
  position: absolute;
  right: -12px;
  cursor: pointer;

  .left-menu-toggle-icon-left {
    transform: rotate(90deg);
    margin-bottom: 1px;
  }
  .left-menu-toggle-icon-right {
    transform: rotate(270deg);
    margin-bottom: 1px;
  }
  
  &:hover{
    background-color: $color-black;
    path{
      fill: $color-white;
    }
  }
}