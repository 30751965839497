@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.options-create-wrapper {
  @include flex-column-space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 22px;
  padding-inline: 28px;
  .options-create-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
    color: $color-dark-blue;
  }
  .options-create-subtitle {
    font-size: 16px;
    line-height: 24px;
    color: $color-dark-blue;
    margin-bottom: 28px;
  }
}
