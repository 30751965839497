@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.create-account-title-step-parent{

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 60px;

    .create-account-title-step-title {
        color: $color-dark-blue;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        text-align: center;
    }
    .create-account-title-step-subtitle {
        margin-top: 16px;
        color: $color-gray-silver;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}