@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.card-option-wrapper {
  @include flex-column-center;
  padding-inline: 12px;
  padding-block: 16px;
  background-color: $color-gray-light;
  border-radius: 8px;
  width: 160px;
  height: 195px;
  margin-inline: 6px;
  border: 1px solid $color-gray-light;
  cursor: pointer;
  .card-option-icon {
  }
  .card-option-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: $color-gray-silver;
    padding-block: 8px;
  }
  .card-option-description {
    text-align: center;
    color: $color-gray-silver;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }
}
.card-option-wrapper:hover {
  border: 1px solid $color-dark-blue;
  .card-option-title {
    color: $color-dark-blue;
  }
  .card-option-description  {
    color: $color-dark-blue;
  }
  .option-create-card-option-wrapper {
    fill: $color-dark-blue;

  }
}
