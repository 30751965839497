@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.preview-code-wrapper {
  @include flex-column-center;
  margin-bottom: 2px;
  .preview-code-title-wrapper {
    @include flex-row-center-space-between;
    .preview-code-title {
      margin-left: 5px;
      font-size: 16px;
      line-height: 24px;
      color: $color-dark-blue;
    }
  }
  .preview-code-code {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

@media (max-height: 719px) {
  .preview-code-wrapper {
    .preview-code-title-wrapper {
      @include flex-row-center-space-between;
      .preview-code-title {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .preview-code-code {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
  }
}