@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/variables.scss';

.communities-form {
  @include flex-column-center;
  align-items: initial;

  .communities-wrapper {
    margin-top: 32px;

    .community-container {
      @include flex-column-start-start;
      border-radius: 16px;
      border: 1px solid $color-gray-silver;
      cursor: pointer;
      padding: 8px 16px;

      .option-radio {
        align-items: center;
        display: flex;

        .radio {
          align-items: center;
          border: 1px solid $color-blue-dodger;
          border-radius: 50%;
          display: flex;
          height: 16px;
          justify-content: center;
          margin-right: 15px;
          padding: 2px;
          width: 16px;

          .radius-selector-content {
            background: transparent;
            border-radius: 50%;
            display: flex;
            width: 100%;
            height: 100%;
          }
        }

        img {
          border-radius: 50%;
          margin-right: 14px;
        }

        .title {
          color: $color-dark-blue;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          margin-right: 8px;
        }

        .city {
          color: $color-dark-blue;
          font-size: 13px;
          font-weight: 600;
          line-height: 19px;
          opacity: 0.7;
        }
      }

      .description {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 12px;
      }

      .keywords {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 5px;
        margin-bottom: 5px;
        opacity: 0.8;
      }

      .tags-content {
        display: flex;
        width: 100%;
      }
    }

    .selected-community {
      border: 1px solid $color-blue-dodger;
      box-shadow: 0px 9px 13px rgba(73, 144, 161, 0.15);

      .radio .radius-selector-content {
        background: $color-blue-dodger !important;
      }
    }
  }

  .text-help {
    align-self: flex-start;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 40px;
    margin-top: 24px;
  }
}

@media (max-width: $screen-mobile-width) {
  .communities-wrapper {
    margin-top: 18px !important;

    .community-container {
      padding: 4px 8px !important;

      .option-radio {
        .radio {
          height: 10px !important;
          margin-right: 10px !important;
          width: 10px !important;
        }

        img {
          width: 35px !important;
        }
      }

      .description {
        font-size: 13px !important;
        line-height: 18px !important;
      }

      .tags-content {
        font-size: 12px;
      }
    }
  }
}
