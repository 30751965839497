@import '../../../assets/css/mixins.scss';
@import '../../../assets/css/colors.scss';

.package-component {
  @include flex-row-center-space-between;
  height: 90px;
  padding: 12px 16px;
  border: 1px solid $color-black;
  border-radius: 8px;
  color: $color-dark-blue;
  margin-bottom: 9px;
  cursor: pointer;
  &.selected {
    border: 1px solid $color-blue-dodger;
    background: $color-blue-hawkes;
  }
  .left {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    .p-name {
      font-size: 16px;
      font-weight: 900;
      line-height: 24px;
    }
    .p-description {
      font-size: 14px;
      overflow-wrap: break-word;
      .duration-text, .impression-text {
        display: block;
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    .price {
      margin-right: 15px;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
    }
    .price-selected {
      color: $color-blue-dodger;
      font-weight: 900;
    }
    .decimals {
      position: absolute;
      margin-top: 1.5px;
      font-size: 10px;
      line-height: 18px;
      width: 12px;
      height: 18px;
    }
  }
}