@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.social-media-form {
  @include flex-column-nowrap;
  margin-top: 28px;
  position: relative;

    .social-media-container-field{

        margin-bottom: 52px;
    
        .social-media-title-field{
            color: $color-blue-dark-profile-fields;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            font-style: normal;
            display: flex;
            flex-direction: row;
            margin-left: 41px;
        }

        .social-media-group-form{

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .social-media-icon{
                margin-right: 16px;

                path {
                    fill: $color-blue-dodger;
                }
            }
        }
    }
}