.checkbox-with-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .label {
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-size: 14px;
    line-height: 21px;
    color: #747479;
  }
}
