@import '../../../assets/css/mixins.scss';
@import '../../../assets/css/variables.scss';

.input-password-component {

  .field-label {
    font-family: $app-font-name;
    font-size: 16px;
    font-weight: 600;
    color: $color-blue-dark-profile-fields;
    margin-bottom: 1px;
    min-height: 25px;
    width: 40px;
  }

  .password-wrapper {
    position: relative;
    z-index: 1;
    height: 40px;

    .field-input-font {
      font-family: 'TitilliumWeb-Regular';
    }
    .field-input {
      @include app-input;
      width: 100%;
    }
    .password-eye {
      cursor: pointer;
      position: relative;
      z-index: 10;
      bottom: 30px;
      left: 290px;
    }
  }
}

.tooltip-position{
  display: flex!important;
  position: sticky!important;
  width: 160px!important;
  padding: 8px 12px!important;
  left: 0!important;
  top: 0!important;
  background-color: $color-red-caramine!important;
  margin-top: -10px!important;

  &::after{
    left: 10%!important;
    border-bottom-color: $color-red-caramine!important;
  }
}
