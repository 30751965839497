.business-images-files {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 40px;
  position: relative; }
  .business-images-files .business-images-close {
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #dcebfc;
    border-radius: 16px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 60px; }
  .business-images-files .business-images-profile-picture {
    border-radius: 116px;
    border: 1px solid #ededed;
    margin-right: 30px; }
