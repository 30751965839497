@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/colors.scss';
@import '../../../../../../assets/css/variables.scss';

.preview-deal-full-preview {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  margin-top: -60px;
  margin-bottom: 26px;
  max-width: 415px;
  min-width: 290px;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 2px 4px rgba(23, 23, 32, 0.2);
  .preview-deal-full-preview-content-wrapper {
    @include flex-column-start-center;
    height: 75%;
    background-color: $color-white;
    width: 100%;
    margin-top: -60px;
    z-index: 1000;
    border-radius: 16px 16px 16px 16px;
    .preview-deal-full-preview-content-header {
      @include flex-row-screen-center;
      align-self: center;
      width: 88%;
      height: 90px;
      min-height: 30px;
      max-width: 372px;
      min-width: 290px;
      border-bottom: 1px solid $color-gray-ghost;
      margin-left: 20px;
      margin-right: 20px;
      .preview-deal-full-preview-content-header-text {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $color-gray-silver;
        padding-left: 5px;
      }
    }
    .preview-deal-full-preview-content-core {
      @include flex-column-center;
      height: 100%;
      width: 100%;
      .preview-deal-full-preview-content-core-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        color: $color-dark-blue;
        margin-bottom: 2px;
        text-align: center;
      }
      .preview-deal-full-preview-content-core-subtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-gray-silver;
      }
      .preview-deal-full-preview-content-core-address {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $color-gray-silver;
        margin-bottom: 16px;
        .preview-deal-full-preview-content-core-icon {
          margin-right: 5.76px;
        }
      }
    }
  }
}

@media (max-height: 719px) {
  .preview-deal-full-preview {
    height: 69% !important;
    width: 49% !important;
    margin-top: 21px !important;
    .preview-deal-full-preview-content-wrapper {
      height: 100%;
      .preview-deal-full-preview-content-header {
        width: 69%;
        border: none;
        margin-right: 20px;
        height: 44px;
        .preview-deal-full-preview-content-header-text {
          font-size: 10px;
        }
      }
      .preview-deal-full-preview-content-core {
        .preview-deal-full-preview-content-core-title {
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 3px;
          margin-top: 6px;
          text-align: center;
        }
        .preview-deal-full-preview-content-core-subtitle {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 3px;
          margin-top: 2px;
        }
        .preview-deal-full-preview-content-core-address {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 3px;
          margin-top: 2px;
          .preview-deal-full-preview-content-core-icon {
          }
        }
      }
    }
  }
}
