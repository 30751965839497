@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/variables.scss';

.action-wrapper {
  align-items: center;
  color: $color-blue-dodger;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;

  &.disabled {
    color: $color-grayish-blue;
    cursor: not-allowed;
  }

  .icon-add-second-timerange {
    margin-right: 8px;

    &.disabled {
      path {
        fill: $color-grayish-blue;
      }
    }
  }
}

@media (max-width: $screen-tablet-width) {
  .business-day-wrapper {
    .business-actions-container {
      flex-wrap: nowrap;

      .action-wrapper {
        .text-action-replicate {
          display: none;
        }
      }
    }
  }
}
