@import '../../assets/css/colors.scss';

.badge-wrapper {
  border-radius: 8px;
  display: flex;
  padding: 18px 25px;
  z-index: 2000;

  &.blue-badge {
    background-color: $color-blue-badge-background;
  }

  .badge-text {
    color: $color-gray-badge-text;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 10.5px;
  }
}
