@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/mixins.scss';

.login-area-container {
  @include flex-column-center;
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 100%;
  .logo-container {
    margin-bottom: 2.5%;
    margin-top: 12.5%;
    position: relative;
    z-index: 1;
  }

  .overlay {
    background-color: rgba($color: $color-dark-blue, $alpha: 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .login-area {
    @include flex-column-start-center;
    background-color: $color-white;
    width: 448px;
    border-radius: 16px;
    position: relative;
    z-index: 3;
  }
}
