.business-identification-step-form {
  width: 100%;
  margin-top: 40px; }
  .business-identification-step-form .register-bottom-buttons {
    align-items: flex-end;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end; }
  .business-identification-step-form .business-identification-error-container {
    margin-top: -28px;
    margin-bottom: 8px; }
