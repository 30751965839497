@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.preview-image-wrapper {
  height: 257px;
  position: relative;
  width: 100%;

  .preview-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .preview-video {
    height: initial;
    object-fit: cover;
    width: 100%;
  }
}
