@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.info-page {
  @include flex-row-start;
  margin-top: 39px;

  .text {
    margin-left: 2.3%;
    color: $color-gray;
    font-size: 16px;
    line-height: 24px;
  }
  .create-deal-info-page-icon {
    transform: rotate(270deg);
    margin-inline: 22px;
  }
  .active-text {
    color: $color-white;
  }
  .inactive-text {
    color: $color-gray;
  }
}

.info-page-profile {
  background: $blue-cyan-dark-shade;
  padding: 31px 25px;
  margin-top: 0px;

  .active-text {
    color: $color-white;
  }
  .inactive-text {
    color: $color-gray;
  }
}

@media (max-height: $screen-tablet-height) {
  .info-page {
    margin-top: 68px; 
  }
}
