@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/variables.scss';
@import '../../../../../../assets/css/colors.scss';

.business-location-form {
  @include flex-column-start-space-between;
  margin-top: 45px;

  .location-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 98px;

    > :first-child {
      border: 1px solid $color-gray-status;
      margin-right: 15px;
      width: 80px;

      .button-text {
        color: $color-gray-status;
      }
    }

    :nth-child(2) {
      background-color: $color-blue-dodger;
      width: 118px;

      .button-text {
        color: $color-white;
      }
    }
  }

  .location-section {
    display: flex;
    flex-direction: column;

    > .text-field,
    > .select-component {
      margin-bottom: 25px;
    }

    .distribute-two-fields-wrapper {
      display: flex;

      > :first-child {
        margin-right: 16px;
        width: calc((100%) / 1);
      }

      :nth-child(2) {
        width: calc((100%) / 1);

        .login-error-message {
          margin-bottom: 23px;
          margin-top: 2px;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile-width) {
  .business-location-form {
    .location-buttons-container {
      margin-top: 30px;
    }
  }
}
