@import '../../../assets/css/mixins.scss';
@import '../../../assets/css/colors.scss';
@import '../../../assets/css/variables.scss';

.input-text-area-wrapper {
  max-height: 65px;
  margin-bottom: 8px;
  .field-label {
    font-family: $app-font-name;
    font-size: 16px;
    font-weight: 600;
    color: $color-blue-dark-profile-fields;
    margin-bottom: 1px;
    min-height: 25px;
    width: 40px;
  }

  .field-input-font {
    font-family: $app-font-name;
  }

  .field-input {
    @include app-input;
    height: auto;
    width: 100%;
  }

  .text-area {
    resize: none;
    margin-top: 4px;
    font-size: 16px;
    color: $color-dark-blue;
  }
}
