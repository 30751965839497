@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/colors.scss';

.preview-deal-card-preview {
  @include flex-column-start-center;
  background-color: $color-white;
  width: 179px;
  margin-right: 48px;
  border-radius: 8px;
  .preview-deal-card-preview-image-wrapper {
    height: 179px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(90, 90, 90, 0.25);
    position: relative;
    .preview-deal-card-preview-offer-tag {
      position: absolute;
      width: 66px;
      height: 32px;
      left: 8px;
      top: 8px;
      background-color: $color-red-caramine;
      font-size: 42px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      color: $color-white;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      .preview-deal-card-preview-offer-tag-text {
        position: relative;
        top: 3px;
      }
    }
    .preview-deal-card-preview-image {
      border-radius: 8px;
      height: 179px;
      object-fit: cover;
      width: 179px;
    }

    .preview-deal-card-preview-video {
      border-radius: 8px;
      height: 179px;
      object-fit: initial;
      width: 100%;
    }
  }
  .preview-deal-card-preview-content-wrapper {
    @include flex-column-space-between;
    background-color: $color-white;
    width: 100%;
    height: 190px;
    margin-top: -15px;
    z-index: 1000;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(23, 23, 32, 0.2);
    .preview-deal-card-preview-content-header {
      @include flex-row-screen-center;
      height: 50px;
      width: 179px;
      .preview-deal-card-preview-content-header-text {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 18px;
        color: $color-gray-silver;
        padding-left: 5px;
      }
    }
    .preview-deal-card-preview-content-core {
      @include flex-column-center;
      .preview-deal-card-preview-content-core-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-dark-blue;
        text-align: center;
      }
    }
    .preview-deal-card-preview-content-core-address {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 18px;
      color: $color-gray-silver;
      margin-bottom: 16px;
      .preview-deal-card-preview-content-core-icon {
        margin-right: 5px;
      }
    }
  }
}
