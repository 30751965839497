@import '../../../../assets/css/mixins.scss';

.forgot-password-form {
  width: 100%;
  height: 95%;
  @include flex-column-center;
  .bottom-container {
    margin-bottom: 52px;
  }
}
