@import '../../../../../../assets/css/colors.scss';
@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/variables.scss';

.images-pictures-wrapper {
  .business-images-files {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    position: relative;

    .business-image-wrapper {
      position: relative;

      img.business-image {
        width: 161.8px;
        height: 160px;
        border-radius: 12px;
      }

      .business-images-close {
        @include flex-row-screen-center;
        background-color: $color-pink-commerce;
        border-radius: 16px;
        cursor: pointer;
        height: 20px;
        left: 150px;
        position: absolute;
        top: -6px;
        width: 20px;
      }
    }

    .business-images-upload-button {
      .button-upload-image-button-wrapper {
        width: 118px;
      }
    }
  }

  @media (max-width: $screen-mobile-width) {
    .business-images-files {
      justify-content: center;
      .business-image {
        margin-right: 10px;
      }
    }
  }
}
