@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

:root {
  --landscape-width: 25; // % OR vw for responsive window resizing!
  --portrait-width: 80vw;
  --header-height: 4rem;
  --secs: 0.4s;
  --bg-left: #394954;
  --bg-right: $color-white;
  --bg-main: $color-white;
}

#layout {
  display: flex; // Flexbox definition
  overflow: hidden; // Disable scrolling on layout
  color: $color-white; // Style in common for sidebars and main area:
  .left-menu-logo {
    position: relative;
    height: var(--header-height);
    text-align: center; // Horizontal center
    padding-top: 40px;
  }
  .left-menu-bottom {
    @include flex-column-nowrap;
    position: absolute;
    bottom: 140px;
    font-size: 16px;
    line-height: 24px;
    color: $color-white-opacity-40;
    width: 100%;
    .logout {
      @include flex-row-center;
      margin-left: 28px;
      margin-top: 32px;
      cursor: pointer;

      .logout-button-text {
        padding-left: 10.5px;
      }
    }
  }
}

// Style in common for both sidebars:
#left {
  width: 0;
  position: relative;
  height: 100%;
  @media (orientation: landscape) {
    &.open {
      width: 265px;
      transition: width var(--secs);
    }
    &.closed {
      width: 97px;
      transition: width var(--secs);
    }
  }
  .sidebar {
    transition: transform var(--secs); // <-- Portrait AND landscape!

    @media (orientation: landscape) {
      &.open {
        width: 265px;
        transition: width var(--secs);
      }
      &.closed {
        width: 97px;
        transition: width var(--secs);
      }
    }
  }
}

// Left sidebar specific style:
#left {
  z-index: 5;
  .sidebar {
    background: var(--bg-left);
    .header {
      left: var(--header-height);
    }
  }
}
