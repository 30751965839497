@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.lateral-panel-form-wrapper {
  background-color: $color-dark-blue-rgba;
  bottom: 0;
  display: flex;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 100;

  .form-wrapper {
    background: $color-white;
    height: 100vh;
    padding: 30px 55px;
    position: absolute;
    right: 0;
    top: 0;
    width: 45%;
    z-index: 200;

    .title {
      font-weight: 600;
      margin-top: 50px;
      display: flex;
      font-size: 24px;
      line-height: 28px;
      color: $blue-cyan-dark-shade;
    }
  }

  @media (max-width: $screen-tablet-width) {
    .form-wrapper {
      padding: 10px 20px;
    }
  }
}
