.main-user-form-container {
  width: 447px; }
  .main-user-form-container .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px; }
  .main-user-form-container .title {
    margin-bottom: 0px; }
  .main-user-form-container .description {
    color: #4d4d4d;
    margin-top: 10px; }
  .main-user-form-container .container-formik {
    margin-top: 30px; }
  .main-user-form-container .container-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 30px;
    margin-right: 30px; }
  .main-user-form-container .container-button {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px; }
