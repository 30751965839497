@import '../../../../assets/css/mixins.scss';

.phone-number-wrapper {
    @include flex-row-center;
    align-items: flex-start;
}

.previous-next-buttons-container{
    margin-top: 25px;
    margin-bottom: 80px;
}