@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/variables.scss';
@import '../../../../assets/css/colors.scss';

.my-information-form {
  form {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .basic-info-wrapper,
    .social-info-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      margin-top: 30px;
      width: calc((100% - 10px) / 2);
    }

    .basic-info-wrapper {
      > .text-field {
        margin-bottom: 28px;
      }

      .change-password-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;

        > .text-field {
          width: calc((70% - 22px) / 1);
        }

        .change-pass-text {
          color: $color-blue-dodger;
          cursor: pointer;
          display: flex;
          font-size: 16px;
          font-weight: 600;
          justify-content: flex-end;
          line-height: 24px;
          margin-top: 20px;
          width: calc((30% - 10px) / 1);
        }
      }

      .form-number-phone-field {
        margin-top: 0;

        .phone-number-wrapper {
          .select-component {
            margin-bottom: 0;
            margin-right: 10px;
            margin-top: 1px;
          }

          .login-error-message {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: $screen-tablet-width) {
    form {
      flex-wrap: wrap;

      .basic-info-wrapper,
      .social-info-wrapper {
        width: calc((100%) / 1);
      }
      .basic-info-wrapper {
        margin-right: 0;
      }
    }
  }
}
