@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 29px;

  &.border {
    border-bottom: 2px solid $color-blue-register-steps;

    .tab-label {
      color: $color-blue-register-steps !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .tab-label {
    color: $color-blue-dark-tabs !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 9px;
  }
}
