@import '../../../../../../assets/css/mixins.scss';
@import '../../../../../../assets/css/colors.scss';
@import '../../../../../../assets/css/variables.scss';

@media (max-height: $screen-tablet-height) {
  .preview-ad-phone-preview {
    margin-bottom: 20px;
    margin-top: 50%;
  }
}

.preview-ad-phone-preview {
  @include flex-column-start-center;
  background-color: $color-white;
  width: 413px;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px $color-black-opacity-2;
  &-content {
    padding: 0 19px;
    width: 100%;
    box-sizing: border-box;
  }

  &-header {
    margin-top: 22px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;

    &-icon {
      img {
        border-radius: 50%;
        height: 50px;
        width: 50px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0 16px;

      &-title {
        color: $color-dark-blue;
        font-size: 16px;
        font-weight: 600;
      }

      &-subtitle {
        color: $color-gray-silver;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  &-description {
    margin-bottom: 8px;
    max-height: 95px;
    width: 100%;
    overflow-wrap: break-word;
  }

  &-image-wrapper {
    height: 375px;
    width: 100%;
    border-radius: 16px;
    position: relative;

    &-image {
      border-radius: 16px;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &-video {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-radius: 16px;
      height: 315px;
      object-fit: initial;
      width: 100%;
    }

    &-bottom-content {
      background-color: black;
      height: 75px;
      position: absolute;
      bottom: 0;
      width: 100%;
      color: white;
      border-radius: 16px;
      display: flex;

      &-left {
        flex: 2;
      }

      &-right {
        flex: 1.2;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-text {
          display: flex;
          align-items: center;
          margin-right: 18px;

          span {
            margin-right: 14px;
            color: $color-white;
            text-decoration: underline;
          }
        }
      }

      &-headline {
        position: relative;
        padding: 16px 18px 0;
        font-weight: 600;
        min-height: 24px;
      }

      &-url {
        padding: 4px 18px 0;
        font-size: 12px;
        font-weight: 400;
        min-height: 23px;
      }
    }
  }

  &-bottom-content {
    @include flex-column-space-between;
    height: 100%;

    &-reactions {
      margin-top: 18px;
    }
  }

  .preview-ad-phone-preview-image-wrapper-bottom-content-right-text {
    text-decoration: none;
  }
  .preview-ad-phone-address {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $color-gray-silver;
    .preview-ad-phone-address-icon {
      padding-top: 13px;
      margin-right: 5.76px;
    }
  }
}
