@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.account-verification-step-form{

    margin-top: 32px;

    .account-verfication-step-description{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #747479;

        .account-verification-step-email{
            font-weight: bold;
        }
    }

    .account-verification-step-button{
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 64px;
    }
}