@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/variables.scss';

.business-contact-info-form {
  form {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 30px;

    .contact-info-first-wrapper,
    .contact-info-second-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: calc((100% - 64px) / 2);

      > .text-field {
        margin-bottom: 28px;

        .login-error-message {
          margin: 0px;
        }
      }

      > .form-number-phone-field {
        margin-top: 0;

        .phone-number-wrapper {
          > .select-component {
            width: calc((30%) / 2);
            margin-right: 10px;
          }

          > .text-field {
            width: calc((170%) / 2);
            .login-error-message {
              margin: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-tablet-width) {
  .business-contact-info-form {
    form {
      flex-wrap: wrap;

      .contact-info-first-wrapper,
      .contact-info-second-wrapper {
        width: calc((100%) / 1) !important;
      }

      .contact-info-second-wrapper {
        margin-top: 20px;
      }
    }
  }
}
